import { BaseWebApiData } from '@/globals/webApiTypes/base.types';
import { LinkType } from '@/globals/webApiTypes/common.types';
import { Location } from '@/sections/contactBlock/ContactBlockLocation';
import { HTMLContent } from '@/sections/markup/Markup.types';
import { wasteGuideConfig } from 'moduleAlias/project/src/pages/wasteGuide/wasteGuideConfig';

interface FrequencyOption {
	title: string;
	slug: string;
}
export interface WasteGuideConfig {
	frequencyOptions: {
		never: FrequencyOption;
		sometimes: FrequencyOption;
		often: FrequencyOption;
	};
}

export const LOT_SIZE_STAGE = 'LOT_SIZE_STAGE';
export const WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE =
	'WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE';
export const WASTE_VOLUME_FOR_LARGE_LOT_STAGE =
	'WASTE_VOLUME_FOR_LARGE_LOT_STAGE';
export const FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE =
	'FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE';
export const FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE =
	'FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE';
export const INCIDENTAL_WASTE_STAGE = 'INCIDENTAL_WASTE_STAGE';
export const ALWAYS_SEPARATE_WASTE_STAGE = 'ALWAYS_SEPARATE_WASTE_STAGE';

export const RESULT_STAGE_FOR_SMALL_LOT = 'RESULT_STAGE_FOR_SMALL_LOT';
export const RESULT_STAGE_FOR_SMALL_VOLUME = 'RESULT_STAGE_FOR_SMALL_VOLUME';
export const RESULT_STAGE_FOR_MEDIUM_LOT = 'RESULT_STAGE_FOR_MEDIUM_LOT';
export const RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME =
	'RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME';
export const RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME =
	'RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME';

export const firstQuestionStage = LOT_SIZE_STAGE;
export const lastQuestionStage = ALWAYS_SEPARATE_WASTE_STAGE;
export const interimResultShowingStage = INCIDENTAL_WASTE_STAGE;
export const resultStages = [
	RESULT_STAGE_FOR_SMALL_LOT,
	RESULT_STAGE_FOR_SMALL_VOLUME,
	RESULT_STAGE_FOR_MEDIUM_LOT,
	RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME,
	RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME,
];

export type Stage =
	| typeof LOT_SIZE_STAGE
	| typeof WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE
	| typeof WASTE_VOLUME_FOR_LARGE_LOT_STAGE
	| typeof FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE
	| typeof FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE
	| typeof INCIDENTAL_WASTE_STAGE
	| typeof ALWAYS_SEPARATE_WASTE_STAGE
	| typeof RESULT_STAGE_FOR_SMALL_LOT
	| typeof RESULT_STAGE_FOR_SMALL_VOLUME
	| typeof RESULT_STAGE_FOR_MEDIUM_LOT
	| typeof RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME
	| typeof RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME;

// mapping for answers to slugs
export const ANSWERS = {
	LOT_SIZE: {
		SMALL: 's',
		MEDIUM: 'm',
		LARGE: 'l',
	},
	WASTE_VOLUME: {
		INCIDENTAL: 's',
		FREQUENTLY_FOR_MEDIUM_LOT: 'sm',
		SEMI_FREQUENTLY_FOR_LARGE_LOT: 'm',
		FREQUENTLY_FOR_LARGE_LOT: 'l',
	},
	ALWAYS_SEPARATE_WASTE: {
		NEVER: wasteGuideConfig.frequencyOptions.never.slug,
		SOMETIMES: wasteGuideConfig.frequencyOptions.sometimes.slug,
		OFTEN: wasteGuideConfig.frequencyOptions.often.slug,
	},
} as const;

export const wasteCategoryKeyMapping = {
	summaryFrequentWaste: {
		answers: [
			'frequentWasteForLargeCompanyAnswers',
			'frequentWasteForMediumCompanyAnswers',
		],
		titleSuffixKey: 'separateForFrequency',
	},
	summaryIncidentalWaste: {
		answers: ['incidentalWasteAnswers'],
		titleSuffixKey: 'separateForVolume',
	},
	summaryAlwaysSeparateWaste: {
		answers: ['alwaysSeparateWasteAnswers'],
		titleSuffixKey: undefined,
	},
} as const;

export type WasteTitleSuffixKey =
	typeof wasteCategoryKeyMapping[keyof typeof wasteCategoryKeyMapping]['titleSuffixKey'];

export type WasteGuideState = {
	stages: Array<Stage>;
	lotSize: typeof ANSWERS.LOT_SIZE[keyof typeof ANSWERS.LOT_SIZE] | undefined;
	wasteVolumeForMediumLot:
		| Exclude<
				typeof ANSWERS.WASTE_VOLUME[keyof typeof ANSWERS.WASTE_VOLUME],
				'm' | 'l'
		  >
		| undefined;
	wasteVolumeForLargeLot:
		| Exclude<
				typeof ANSWERS.WASTE_VOLUME[keyof typeof ANSWERS.WASTE_VOLUME],
				'sm'
		  >
		| undefined;
	frequentWasteForMediumCompany: string | undefined;
	frequentWasteForLargeCompany: Set<string>;
	incidentalWaste: Set<string>;
	alwaysSeparateWaste: Record<string, string>;
};

export type WasteGuideSummary = {
	title: string;
	text: HTMLContent;
	linkText: string;
	aside: { title: string };
};

type Results = {
	title: string;
	text: HTMLContent;
	noResultText: string;
};

export type WasteQuestion = {
	title: string;
	text: HTMLContent;
	errorMessage: string;
	example?: {
		title: string;
		text: HTMLContent;
	};

	aside?: {
		title: string;
		text: HTMLContent;
	};
	faqText?: string;
	faqLink?: {
		href: string;
		text: string;
		isExternal: boolean;
	};
};

export type WasteGuidePageData = BaseWebApiData & {
	relatedSubjects?: {
		text: string;
		linkList: Array<LinkType>;
	};
	startScreen: {
		intro?: HTMLContent;
		asideText?: HTMLContent;
		ctaButtonText: string;
		summaries: {
			title: string;
			description: string;
			summaryFrequentWaste: WasteGuideSummary;
			summaryIncidentalWaste: WasteGuideSummary;
			summaryAlwaysSeparateWaste: WasteGuideSummary;
		};
	};
	wasteResults: {
		title: string;
		intros: {
			forSmallLot: HTMLContent;
			forMediumLot: HTMLContent;
			forSmallVolume: HTMLContent;
			forLargeLotAndMediumVolume: HTMLContent;
			forLargeLotAndLargeVolume: HTMLContent;
		};
		frequentWasteResults: Results;
		incidentalWasteResults: Results;
		explanations: Array<{
			type: 'info' | 'alert';
			title: string;
			text: HTMLContent;
		}>;
		wasteGuidePdf: {
			header: string;
			title: string;
			outro: string;
			infoBlock1: {
				title: string;
				text: string;
			};
			infoBlock2: {
				title: string;
				text: string;
			};
			footer: {
				title: string;
				description: string;
				url: string;
				disclaimer: string;
			};
			downloadButtonText: string;
		};
	};

	companyQuestions: {
		lotSize: WasteQuestion;

		wasteVolume: WasteQuestion;
	};
	companyAnswers: {
		lotSize: Array<{
			slug: typeof ANSWERS.LOT_SIZE[keyof typeof ANSWERS.LOT_SIZE];
			label: string;
		}>;
		wasteVolumeForMediumLot: Array<{
			slug: Exclude<
				typeof ANSWERS.WASTE_VOLUME[keyof typeof ANSWERS.WASTE_VOLUME],
				'm' | 'l'
			>;
			label: string;
		}>;
		wasteVolumeForLargeLot: Array<{
			slug: Exclude<
				typeof ANSWERS.WASTE_VOLUME[keyof typeof ANSWERS.WASTE_VOLUME],
				'sm'
			>;
			label: string;
		}>;
	};
	interimResults: {
		smallLot: {
			text: HTMLContent;
		};
		smallWasteVolume: {
			text: HTMLContent;
		};
	};
	commonWasteQuestions: {
		incidentalWaste: WasteQuestion;
		alwaysSeparateWaste: WasteQuestion;
	};
	wasteQuestionsForMediumCompany: {
		frequentWaste: WasteQuestion;
	};
	wasteQuestionsForLargeCompany: {
		frequentWaste: WasteQuestion;
	};
	wasteAnswers: {
		frequentWasteForMediumCompanyAnswers: Array<string>;
		frequentWasteForLargeCompanyAnswers: Array<string>;
		incidentalWasteAnswers: Array<string>;
		alwaysSeparateWasteAnswers: Array<{
			title: string;
			slug: string;
			wastes: Array<string>;
		}>;
	};
	wastes: {
		[Key in string]: {
			slug: string;
			title: string;
			description?: HTMLContent;
			pdfDescription?: string;
			separateForFrequency?: string;
			separateForVolume?: string;
			resultExplanation?: HTMLContent;
		};
	};

	contact?: {
		organisation?: string;
		introText: string;
		title: string;
		phoneNumbers?: Array<{
			phoneNumber: string;
			organisation?: string;
		}>;
		xDotComLink?: {
			socialMediaServiceName: string;
			href: string;
			isExternal: boolean;
		};
		email?: LinkType;
		links?: Array<LinkType>;
		forumLinks?: Array<LinkType>;
		locations?: Array<Location>;
		formLink?: LinkType;
	};
};
